import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day07Mar from "./dia-07-mar";
import Day08Mar from "./dia-08-mar";
import Day09Mar from "./dia-09-mar";
import Day10Mar from "./dia-10-mar";
import Day11Mar from "./dia-11-mar";
import Day12Mar from "./dia-12-mar";
import Day13Mar from "./dia-13-mar";

import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold, StrongWhite } from "../../../components/Textstyles/Styles";
import ModImage from "../../../components/Chronology/Modules/ModImage";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <SecWeekly period="07 Marzo - 13 Marzo" week="Semana 0 de confinamiento">
        <SecWeeklyColumn>
          La Organización Mundial de la Salud declara que el nuevo brote de
          coronavirus es <strong>una pandemia mundial</strong>
          <ModDottedLine />
          La Comunidad de <strong>Madrid</strong> supera los{" "}
          <strong>1000 casos positivos</strong> en COVID-19
          <ModDottedLine />
          Todos los grandes <strong>eventos deportivos</strong> se realizarán a{" "}
          <strong>puerta cerrada</strong>
        </SecWeeklyColumn>
        <SecWeeklyColumn>
          <ModImage
            src="/images/svg/Semana0-mapacontagios.svg"
            alt="resumen semanal España"
          />
        </SecWeeklyColumn>
      </SecWeekly>
      <Day13Mar {...props} />
      <ModMegaBanner>
        El gobierno anuncia que procederá a declarar el{" "}
        <StrongGold>estado de alarma</StrongGold> por crisis sanitaria debido al
        brote epidémico de COVID-19
      </ModMegaBanner>
      <Day12Mar {...props} />
      <ModMegaBanner>
        La <StrongWhite>Organización Mundial de la Salud</StrongWhite> declara
        que el nuevo brote de <StrongGold>coronavirus SARS-Cov-2</StrongGold> es
        una <StrongWhite>pandemia mundial</StrongWhite>.
      </ModMegaBanner>
      <Day11Mar {...props} />
      <Day10Mar {...props} />
      <Day09Mar {...props} />
      <Day08Mar {...props} />
      <Day07Mar {...props} />
    </LayoutWeek>
  );
};

export default Week;
